import { template as template_cb05c0ac1adc416c8da7c7337d3c4cae } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ActivityCell = template_cb05c0ac1adc416c8da7c7337d3c4cae(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="activity"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="activity"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ActivityCell;
